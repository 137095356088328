// Based on
// https://www.ryanbateman.space/blog/tutorial-gatsbyjs-for-drupalers-or-how-to-jamstack-ify-your-drupal-site-with-gatsbyjs/
import { graphql } from "gatsby"
import moment from 'moment'
import React from "react"
import { Link, history } from 'gatsby'
import Layout from '../components/layout'
import { IoIosArrowBack } from 'react-icons/io'

const BlogPost = ({ data }) => (
  <Layout>
    <article className="w-full flex flex-col relative">
      <Link to='/' className="rounded-full flex justify-center items-center p-4 bg-gray-300 text-black w-min h-min absolute left-4 top-4 hover:bg-gray-200"><IoIosArrowBack /></Link>
      <div className="w-full p-4 text-center flex flex-col">
        <img src={data.nodeTvSeries.relationships.field_image.localFile.url} alt={data.nodeTvSeries.title} className='mx-auto w-64 mb-3' />
        <h1 className="font-bold text-2xl mx-auto pb-4 border-b border-gray-300 w-content px-16">{data.nodeTvSeries.title}</h1>
        <div className="max-w-xl mx-auto text-justify pt-4" dangerouslySetInnerHTML={{__html: data.nodeTvSeries.body.processed}} />
      </div>
    </article>
  </Layout>
)

export default BlogPost

export const query = graphql`
  query($slug: String!) {
    nodeTvSeries (path: { alias: { eq: $slug}}) {
      title
      created
      changed
      body {
	      processed
      }
      relationships {
        field_image {
          localFile {
            url
          }
        }
        field_tags {
          name
        }
      }
    }
  }
`
